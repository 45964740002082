import { StepBase } from 'subframe/index';
import { Text } from '@subframe/core';
import { Link } from '@mui/material';
import { Container, Stack } from 'components/utils';
import styles from './UpdateK8sConnector.module.scss';
import { CopyToClipboard } from 'src/components/design-system/CopyToClipboard';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const UpdateHelmK8sConnector = () => {
  const { logEvent } = AnalyticsEventLogger();

  const [triggerOpen, setTriggerOpen] = useState({
    firstOpen: true,
    secondOpen: false,
    thirdOpen: false,
  });
  const addClusterPath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters/new',
  );
  return (
    <Container className={styles['page-contents']}>
      <Stack spacing={2}>
        <StepBase
          id="helm-kubectl-get-current-version"
          stepTitle="Get the current version of Helm chart installed in your K8s
            cluster"
          stepBody=""
          stepNumber="1"
          open={triggerOpen.firstOpen}
          onOpenChange={() =>
            setTriggerOpen((prev) => {
              return { ...prev, firstOpen: !prev.firstOpen };
            })
          }
          actionButtons={<></>}
        >
          <Stack className="self-stretch">
            <CopyToClipboard
              language="shell"
              text={'helm list -n chkk-system -o json'}
              onCopy={() =>
                logEvent('terminal-copy-upgrade-connector-helm', {
                  command: 'helm-list-chkksystem',
                })
              }
            />
          </Stack>
        </StepBase>

        <StepBase
          id="helm-remove-installation"
          stepTitle="Remove the current installation"
          stepBody="To remove the previous installation of K8s Connector, use the following command:"
          stepNumber="2"
          open={triggerOpen.secondOpen}
          onOpenChange={() =>
            setTriggerOpen((prev) => {
              return { ...prev, secondOpen: !prev.secondOpen };
            })
          }
          actionButtons={<></>}
        >
          <Stack className="self-stretch" spacing={4}>
            <CopyToClipboard
              language="shell"
              text={'helm uninstall chkk-cluster-agent -n chkk-system'}
              onCopy={() =>
                logEvent('terminal-copy-remove-connector-helm', {
                  command: 'helm-remove',
                })
              }
            />
            <Text>
              Your cluster properties, scans, Upgrade Plans, and Add-on
              information will be preserved with the next installation.
            </Text>
          </Stack>
        </StepBase>

        <StepBase
          id="helm-re-add-cluster"
          stepTitle={`Re-onboard your cluster to Chkk using Chkk Operator`}
          stepBody=""
          stepNumber="3"
          lastStep={true}
          open={triggerOpen.thirdOpen}
          onOpenChange={() =>
            setTriggerOpen((prev) => {
              return { ...prev, thirdOpen: !prev.thirdOpen };
            })
          }
          actionButtons={<></>}
        >
          <Stack spacing={4} className="self-stretch">
            <Text>
              Follow the steps{' '}
              {
                // TODO: Replace with subframe RouterLink
                <Link to={addClusterPath} component={RouterLink}>
                  here
                </Link>
              }{' '}
              to onboard your cluster.
            </Text>
          </Stack>
        </StepBase>
      </Stack>
    </Container>
  );
};
