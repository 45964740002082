// @subframe/sync-disable
'use client';
/*
 * Documentation:
 * Code Block — https://app.subframe.com/library?component=Code+Block_94ec0ccd-2a67-4176-9a39-0151f428b7f3
 */

// custom props/logic added with "// custom code" comments

import React from 'react';
import * as SubframeCore from '@subframe/core';
import { SyntaxHighlighter } from 'components/design-system';

interface CodeBlockRootProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: string;
  className?: string;
  language?: string; // custom code
}

const CodeBlockRoot = React.forwardRef<HTMLElement, CodeBlockRootProps>(
  function CodeBlockRoot(
    { children, className, language, ...otherProps }: CodeBlockRootProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'flex flex-col items-start rounded bg-neutral-50 pt-2 pr-3 pb-2 pl-3 shadow',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        {children ? (
          <SyntaxHighlighter language={language} text={children} />
        ) : null}
      </div>
    );
  },
);

export const CodeBlock = CodeBlockRoot;
