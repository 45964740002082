/*
 * Documentation:
 * [Design system] Copy to clipboard — https://app.subframe.com/library?component=%5BDesign+system%5D+Copy+to+clipboard_c58ef7de-f105-487a-971c-99701c362001
 * Copy to clipboard button — https://app.subframe.com/library?component=Copy+to+clipboard+button_e8c76626-6462-4f2f-b595-38d530d427e8
 */
import React, { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import * as SubframeCore from '@subframe/core';
import { Tooltip } from 'subframe/components/Tooltip';
import { SyntaxHighlighter } from './SyntaxHighlighter';

interface CopyToClipboardRootProps
  extends React.ComponentProps<typeof SubframeCore.CopyToClipboard.Root> {
  text?: string;
  language?: string;
  singleLine?: boolean;
  className?: string;
  clipboardText?: string;
  tooltipText?: string;
}

// how long it takes to reset from checkmark back to clipboard icon
const TIME_TO_RESET = 2000;

const CopyToClipboardRoot = React.forwardRef<
  HTMLElement,
  CopyToClipboardRootProps
>(function CopyToClipboardFieldRoot(
  {
    text,
    language,
    singleLine = false,
    className,
    tooltipText = 'Copy to clipboard',
    onCopy,
    ...otherProps
  }: CopyToClipboardRootProps,
  ref,
) {
  const { enqueueSnackbar } = useSnackbar();
  const [copied, setCopied] = useState(false);
  const copiedTimer = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      if (copiedTimer.current) {
        window.clearTimeout(copiedTimer.current);
      }
    };
  }, []);

  return (
    <SubframeCore.Tooltip.Provider>
      <SubframeCore.Tooltip.Root>
        <SubframeCore.Tooltip.Trigger asChild={true}>
          <SubframeCore.CopyToClipboard.Root
            clipboardText={text}
            onCopy={() => {
              setCopied(true);
              if (copiedTimer.current) {
                window.clearTimeout(copiedTimer.current);
              }
              copiedTimer.current = window.setTimeout(() => {
                setCopied(false);
              }, TIME_TO_RESET);
              enqueueSnackbar('Copied to clipboard', {
                variant: 'success',
                autoHideDuration: TIME_TO_RESET,
              });
              onCopy?.();
            }}
            {...otherProps}
          >
            <div
              className={SubframeCore.twClassNames(
                'group/c58ef7de flex w-full items-start gap-1.5 rounded border border-solid border-neutral-border bg-neutral-100 pt-0.5 pr-0.5 pb-0.5 pl-2',
                className,
                {
                  relative: !singleLine,
                  'overflow-hidden': singleLine,
                },
              )}
              ref={ref as any}
              data-cy={'copy-to-clipboard-button'}
              {...otherProps}
            >
              <div className="flex max-h-[160px] grow shrink-0 basis-0 items-start gap-1 mr-2 pt-0.5 overflow-auto [overflow-wrap:anywhere] [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-thumb]:rounded-[20px] [&::-webkit-scrollbar-thumb]:border-4 [&::-webkit-scrollbar-thumb]:border-solid [&::-webkit-scrollbar-thumb]:border-transparent [&::-webkit-scrollbar-thumb]:bg-[rgba(255,255,255,0.2)] [&::-webkit-scrollbar-thumb]:bg-clip-border">
                {text ? (
                  <SyntaxHighlighter language={language} text={text} />
                ) : null}
              </div>
              <div
                className={SubframeCore.twClassNames(
                  'group/e8c76626 flex h-6 w-6 cursor-pointer flex-col items-center justify-center gap-2 rounded hover:bg-neutral-100 absolute right-[0]',
                )}
                data-cy="cb-button"
              >
                <SubframeCore.Icon
                  className="text-body font-body text-subtext-color group-hover/e8c76626:text-default-font "
                  name={copied ? 'FeatherCheck' : 'FeatherClipboard'}
                />
              </div>
            </div>
          </SubframeCore.CopyToClipboard.Root>
        </SubframeCore.Tooltip.Trigger>
        <SubframeCore.Tooltip.Portal>
          <SubframeCore.Tooltip.Content
            side="bottom"
            align="center"
            sideOffset={8}
            asChild={true}
          >
            <Tooltip>{tooltipText}</Tooltip>
          </SubframeCore.Tooltip.Content>
        </SubframeCore.Tooltip.Portal>
      </SubframeCore.Tooltip.Root>
    </SubframeCore.Tooltip.Provider>
  );
});

export const CopyToClipboard = CopyToClipboardRoot;
