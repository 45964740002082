import React from 'react';
import { Prism } from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/prism';

const SyntaxHighlighterRoot = ({
  language = 'plaintext',
  codeStyle,
  text,
}: {
  language?: string;
  codeStyle?: React.CSSProperties;
  text: string;
}) => {
  // todo: find a way to highlight cli tools commands consistently
  // disabling cli commands highlight temporarily
  const highlightLanguage = ['bash', 'shell'].includes(language)
    ? 'plaintext'
    : language;
  return (
    <Prism
      language={highlightLanguage}
      style={nightOwl}
      wrapLongLines={true}
      customStyle={{
        background: 'transparent',
        padding: 0,
        margin: 0,
        font: 'var(--body)',
        color: 'var(--subtext-color)',
      }}
      codeTagProps={{
        style: {
          maxWidth: '100%',
          overflowWrap: 'anywhere',
          overflow: 'hidden',
          ...codeStyle,
        },
      }}
    >
      {text}
    </Prism>
  );
};

export const SyntaxHighlighter = SyntaxHighlighterRoot;
